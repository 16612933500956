<template>
    <v-dialog :persistent="loading" v-model="dialog" max-width="500">
        <template v-slot:activator="{ on: dialog }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                        v-if="
                            actionType === actionTypes.REVOKE_INVITATION ||
                            actionType === actionTypes.REVOKE_REQUEST ||
                            actionType === actionTypes.REJECT_REQUEST
                        "
                        v-on="{ ...tooltip, ...dialog }"
                        icon
                        small
                        color="error">
                        <v-icon>clear</v-icon>
                    </v-btn>
                    <v-btn small color="success" v-else-if="actionType === actionTypes.APPROVE_REQUEST" v-on="{ ...tooltip, ...dialog }" icon>
                        <v-icon>done</v-icon>
                    </v-btn>
                    <v-btn small v-else-if="actionType === actionTypes.RESEND_INVITATION" v-on="{ ...tooltip, ...dialog }" icon><v-icon>reply</v-icon></v-btn>
                </template>
                <span>{{ actionType }}</span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title>
                <div class="d-flex align-center secondary--text">
                    <v-icon v-if="actionType === actionTypes.REVOKE_INVITATION || actionType === actionTypes.REVOKE_REQUEST" class="mr-1">clear</v-icon>
                    <v-icon v-else-if="actionType === actionTypes.RESEND_INVITATION" class="mr-1">reply</v-icon>
                    <span class="text-uppercase">{{ actionType }}</span>
                </div>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-divider></v-divider>

            <v-card-text class="mt-1">
                <div class="d-flex flex-column">
                    <span v-if="actionType === actionTypes.REVOKE_INVITATION" class="subtitle-1">Are you sure you want to revoke this invitation:</span>
                    <span v-else-if="actionType === actionTypes.REVOKE_REQUEST" class="subtitle-1">Are you sure you want to revoke this request:</span>
                    <span v-else-if="actionType === actionTypes.RESEND_INVITATION" class="subtitle-1">Are you sure you want to re-send this invitation:</span>
                    <v-card class="mt-3" flat outlined>
                        <v-card-text>
                            <div class="d-flex flex-column">
                                <span class="caption font-weight-bold text-uppercase">requestee</span>
                                {{ invitationInfo.requestee_email }}
                                <span class="caption font-weight-bold text-uppercase mb-2 mt-5">roles</span>
                                <div class="d-flex flex-column mb-1" v-for="(role, index) in invitationInfo.roles" :key="index">
                                    <span v-if="role.role_type === invitationAndRequestRoleTypes.INSTANCE_ROLE" class="d-flex align-center">
                                        <v-icon v-if="role.Name === roleTypes.INSTANCE_VIEWER" class="mr-1 ml-1">visibility</v-icon>
                                        <v-icon v-else-if="role.Name === roleTypes.INSTANCE_EDITOR" class="mr-1 ml-1">edit</v-icon>
                                        {{ role.Instance }}
                                    </span>
                                    <span v-else-if="role.role_type === invitationAndRequestRoleTypes.ORG_ROLE" class="d-flex align-center">
                                        <v-icon class="mr-1 ml-1">apartment</v-icon>
                                        {{ role.Organization }}
                                    </span>
                                    <span v-else-if="role.role_type === invitationAndRequestRoleTypes.SPACE_ROLE" class="d-flex align-center">
                                        <v-icon class="mr-1 ml-1">security</v-icon>
                                        {{ role.Space }}
                                    </span>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="actionType === actionTypes.REVOKE_INVITATION || actionType === actionTypes.REVOKE_REQUEST"
                    :loading="loading"
                    @click="revokeInvitationOrRequest"
                    color="error"
                    text>
                    Revoke
                </v-btn>
                <v-btn v-if="actionType === actionTypes.RESEND_INVITATION" :loading="loading" @click="resendInvitation" color="primary" text>Send again</v-btn>
                <v-btn v-if="actionType === actionTypes.APPROVE_REQUEST" :loading="loading" @click="approveRequest" color="success" text>Approve</v-btn>
                <v-btn v-if="actionType === actionTypes.REJECT_REQUEST" :loading="loading" @click="rejectRequest" color="error" text>Reject</v-btn>
                <v-btn @click="dialog = false" color="secondary" text :disabled="loading">cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { invitationsAndRequests } from '@/mixins/invitationsAndRequests'
import { enumsData } from '@/mixins/enums'

export default {
    name: 'InvitationRevokeDialog',
    mixins: [invitationsAndRequests, enumsData],
    props: {
        invitationInfo: Object,
        actionType: String,
        successFetchAction: Function
    },
    data() {
        return {
            dialog: false,
            loading: false
        }
    },
    methods: {
        submitAction: function (apiURL, successMessage, failureMessage) {
            this.loading = true
            this.$axios
                .post(apiURL, { timeout: 300000 })
                .then(response => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: successMessage,
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.successFetchAction()
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: failureMessage,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.dialog = false
                    this.loading = false
                })
        },
        revokeInvitationOrRequest: function () {
            const apiURL =
                this.actionType === this.actionTypes.REVOKE_INVITATION
                    ? `/invitations/revoke/${this.$props.invitationInfo.token}`
                    : `/requests/revoke/${this.$props.invitationInfo.token}`
            const successMessage = this.actionType === this.actionTypes.REVOKE_INVITATION ? 'Invitation successfully revoked!' : 'Request successfully revoked!'
            const failureMessage =
                this.actionType === this.actionTypes.REVOKE_INVITATION
                    ? 'Failed to revoke the invitation, please try again later.'
                    : 'Failed to revoke the request, please try again later.'
            this.submitAction(apiURL, successMessage, failureMessage)
        },
        resendInvitation: function () {
            const successMessage = 'Invitation successfully resent!'
            const failureMessage = 'Failed to resend invitation, please try again later.'
            const apiURL = `/invitations/resend/${this.$props.invitationInfo.token}`
            this.submitAction(apiURL, successMessage, failureMessage)
        },
        approveRequest: function () {
            const successMessage = 'Request successfully approved!'
            const failureMessage = 'Failed to approve the request, please try again later.'
            const apiURL = `/requests/accept/${this.$props.invitationInfo.token}`
            this.submitAction(apiURL, successMessage, failureMessage)
        },
        rejectRequest: function () {
            const successMessage = 'Request successfully rejected!'
            const failureMessage = 'Failed to reject the request, please try again later.'
            const apiURL = `/requests/reject/${this.$props.invitationInfo.token}`
            this.submitAction(apiURL, successMessage, failureMessage)
        }
    },
    watch: {
        globalDialog: function (nextVal, preVal) {
            this.dialog = nextVal
        }
    },
    beforeDestroy() {
        this.$store.dispatch('hideGlobalDialog')
    }
}
</script>
